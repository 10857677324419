import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import useSiteMetadata from "../hooks/use-site-metadata"

type AnalyticsProps = {}

const Analytics = ({ }: AnalyticsProps) => {
  const { tealiumUrlProd, tealiumUrlQa, environment } = useSiteMetadata()

  if (!tealiumUrlProd) {
    return null
  }

  const isProduction = environment?.endsWith(`-main`) || environment?.includes(`-split-`) || environment === "feature/master"
  const envSpecificTealiumUrl = isProduction ? tealiumUrlProd : tealiumUrlQa

  return (
    <Helmet>
      <script>
        {
          `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W8MDC2V');
          `
        }
      </script>
    </Helmet>
  )
}

export default Analytics
