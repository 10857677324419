import {createMarkup} from "../../../utils/markup-creator";
import React, {useEffect, useState} from "react";
import programs from "../../data/uhsm.json"
import Icon from "../icons";
import {SectionType} from "../../types";
import {updateLead} from "../../api/leadsUpdate";
import Spinner from "../forms/components/spinner";

type SectionProps = {
    section: SectionType
    index: any
    [key: string]: any
}

type Plan = {
    plan?: string
    members?: string
    min_age?: number
    max_age?: number
    price?: number | undefined
    annual_share?: number | undefined
    annual_share_info?: string
    annual_max?: number | undefined
    annual_max_info?: string
    wellness_preventative?: number | undefined
    wellness_preventative_info?: string
    primary_care_visit?: number | undefined
    urgent_care_visit?: number | undefined
    rx_benefits?: string
    rx_benefits_info?: string
}


const ToolTip = ({status, text}: { status: boolean, text: string | undefined }) => {
    return <div className="relative mt-0">
        {status && (
            <div role="tooltip"
                 className="z-20 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-gray-500 p-4 rounded">
                <svg className="absolute left-0 -ml-2 bottom-0 top-0 h-full" width="9px" height="16px"
                     viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFFFFF">
                            <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                                <g id="Group-2" transform="translate(24.000000, 0.000000)">
                                    <polygon id="Triangle"
                                             transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                                             points="4.5 57.5 12.5 66.5 -3.5 66.5"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <p className="text-white">{text}</p>
            </div>
        )}{" "}
    </div>
}

const Currency = (number:  number | undefined ) => {
    return number?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
    })
}


const ProductsSection = ({section, index, ...props}: SectionProps) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setemail] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [familySize, setFamilySize] = useState('')
    const [age, setAge] = useState(18)
    const [stateData, setData] = useState({
        isSubmitting: false,
    })
    const [tooltipStatus, setTooltipStatus] = useState("")

    useEffect(() => {
        // @ts-ignore
        setFirstName(window.sessionStorage.getItem('firstName'))
        // @ts-ignore
        setLastName(window.sessionStorage.getItem('lastName'))
        // @ts-ignore
        setemail(window.sessionStorage.getItem('email'))
        // @ts-ignore
        setDateOfBirth(window.sessionStorage.getItem('dateOfBirth'))
        // @ts-ignore
        setFamilySize(window.sessionStorage.getItem('familySize'))
    }, [])

    useEffect(() => {
        const birth_year = new Date(dateOfBirth).getFullYear()
        const actual_year = new Date().getFullYear()
        setAge(actual_year - birth_year)
    }, [dateOfBirth])

    const onSubmit = async (itm: any) => {
        setData({...stateData, isSubmitting: true})
        window.sessionStorage.setItem('namePlan', itm.plan)
        // @ts-ignore
        window.sessionStorage.setItem('ratePlan', itm.price)
        // @ts-ignore
        window.sessionStorage.setItem('product', JSON.stringify(itm))

        try {
            const result = await updateLead(itm.plan)

            if (result) {
                location.assign('/thank-you-quote')
            }

            setData({...stateData, isSubmitting: false})
        } catch (err) {
            setData({...stateData, isSubmitting: false})
            alert(`Your request was not sent successfully.  ${err.message}`)
        }
    }

    const renderProducts = programs.filter((it) => ((it.min_age <= age) && (it.max_age >= age) && (it.members === familySize))).map((itm: Plan, im) => {
        return <div className={"col-span-1 shadow-md rounded p-5 hover:shadow-xl "}
                    style={{fontFamily: 'Nunito'}}
                    key={' product ' + im}>
            <div className={"grid grid-cols-4 gap-2"}>
                <div className={"col-span-1"}>
                    <img
                        style={{objectFit: 'contain', width: 50, height: 50}}
                        src="/images/uploads/uhsm.png"
                        alt='uhsm'
                    />
                </div>
                <div className={"col-span-3"}>
                    <h5 className={"font-bold"}
                        style={{fontFamily: 'Nunito'}}>
                        {itm.plan}
                    </h5>

                    {/*<div className={"flex flex-row"}>*/}
                    {/*                <span style={{fontFamily: 'Nunito'}}*/}
                    {/*                      className={"font-light text-sm pr-2 text-gray-400"}>*/}
                    {/*                    Rating:*/}
                    {/*                </span>*/}

                    {/*    <img*/}
                    {/*        className={'block md:hidden lg:block'}*/}
                    {/*        style={{objectFit: 'contain', height: 14, width: 14}}*/}
                    {/*        src="/images/uploads/star_fill.png"*/}
                    {/*        alt='star'*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        className={'block md:hidden lg:block'}*/}
                    {/*        style={{objectFit: 'contain', height: 14, width: 14}}*/}
                    {/*        src="/images/uploads/star_fill.png"*/}
                    {/*        alt='star'*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        className={'block md:hidden lg:block'}*/}
                    {/*        style={{objectFit: 'contain', height: 14, width: 14}}*/}
                    {/*        src="/images/uploads/star_fill.png"*/}
                    {/*        alt='star'*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        className={'block md:hidden lg:block'}*/}
                    {/*        style={{objectFit: 'contain', height: 14, width: 14}}*/}
                    {/*        src="/images/uploads/star_fill.png"*/}
                    {/*        alt='star'*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        className={'block md:hidden lg:block'}*/}
                    {/*        style={{objectFit: 'contain', height: 14, width: 14}}*/}
                    {/*        src="/images/uploads/star_fill.png"*/}
                    {/*        alt='star'*/}
                    {/*    />*/}

                    {/*    /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                    {/*    /!*      color="yellow-700"/>*!/*/}
                    {/*    /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                    {/*    /!*      color="yellow-700"/>*!/*/}
                    {/*    /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                    {/*    /!*      color="yellow-700"/>*!/*/}
                    {/*    /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                    {/*    /!*      color="yellow-700"/>*!/*/}
                    {/*    /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                    {/*    /!*      color="yellow-700"/>*!/*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={"pt-7"}>
                <div className={"grid grid-cols-3 gap-2"}  style={{fontSize:15}}>
                    <div className={"col-span-2"}>
                        <div className={"text-gray-400 align-middle"}>
                            <Icon onMouseEnter={() => setTooltipStatus(im + 'Annual Share')}
                                  onMouseLeave={() => setTooltipStatus('')}
                                  className={"mr-1"} icon="info" size={15} color="gray-400"/>
                            <span >Annual Share - AMCS</span>
                            <ToolTip status={tooltipStatus === im + 'Annual Share'} text={itm.annual_share_info}/>
                        </div>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{Currency(itm.annual_share)}</span>
                    </div>

                    <div className={"col-span-2"}>
                        <div className={"text-gray-400"}>
                            <Icon onMouseEnter={() => setTooltipStatus(im + 'Annual Max')}
                                  onMouseLeave={() => setTooltipStatus('')}
                                  className={"mr-1"} icon="info" size={15} color="gray-400"/>
                            <span className={"text-gray-400"}>Annual Max</span>
                            <ToolTip status={tooltipStatus === im + 'Annual Max'} text={itm.annual_max_info}/>
                        </div>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{Currency(itm.annual_max)}</span>
                    </div>


                    <div className={"col-span-2"}>
                        <div className={"text-gray-400 text-light"}>
                            <Icon onMouseEnter={() => setTooltipStatus(im + 'Wellness')}
                                  onMouseLeave={() => setTooltipStatus('')}
                                  className={"mr-1"} icon="info" size={15} color="gray-400"/>
                            <span className={"text-gray-400 text-light"}>
                                        Wellness & preventative
                                    </span>

                            <ToolTip status={tooltipStatus === im + 'Wellness'} text={itm.wellness_preventative_info}/>
                        </div>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{Currency(itm.wellness_preventative)}</span>
                    </div>

                    <div className={"col-span-2"}>
                        <span className={"text-gray-400 text-light"}>Primary Care Visit</span>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{Currency(itm.primary_care_visit)}</span>
                    </div>

                    <div className={"col-span-2"}>
                        <span className={"text-gray-400 text-light"}>Urgent Care Visit </span>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{Currency(itm.urgent_care_visit)}</span>
                    </div>


                    <div className={"col-span-2"}>
                        <div className={"text-gray-400 text-light"}>
                            <Icon onMouseEnter={() => setTooltipStatus(im + 'Rx')}
                                  onMouseLeave={() => setTooltipStatus('')}
                                  className={"mr-1"} icon="info" size={15} color="gray-400"/>
                            <span className={"text-gray-400 text-light"}>
                                        Rx Benefits
                                    </span>

                            <ToolTip status={tooltipStatus === im + 'Rx'} text={itm.rx_benefits_info}/>
                        </div>
                    </div>
                    <div className={"col-span-1 text-right"}>
                        <span className={"text-black"}>{itm.rx_benefits}</span>
                    </div>
                </div>
            </div>
            <div className={"mt-4 pt-4 border-t border-gray-200"}>
                <h5 className={"text-right text-2xl"} style={{fontFamily: 'Montserrat', color: '#F3B220'}}>
                    {Currency(itm.price)}
                </h5>
                <div className={"pt-5 text-right"}>
                    <button
                        disabled={stateData.isSubmitting}
                        style={{backgroundColor: '#F3B220', borderColor: '#F3B220'}}
                        className={'h-10 text-sm font-bold w-1/2 lg:w-1/2 button secondary'}
                        onClick={() => onSubmit(itm)}>
                        {stateData.isSubmitting &&
                            <Spinner className="float-left h-7 w-5 text-white" size={5} color="white"/>}
                        Apply now
                    </button>
                </div>
            </div>
        </div>
    })


    return (
        <div className={`relative ${section.advanced?.class || ``}`}>
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0 mb-8">
                {section.blurb && <div className="pb-6" dangerouslySetInnerHTML={createMarkup(section.blurb)}/>}
                <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
                    {renderProducts}
                </div>
            </div>
        </div>
    )
}
export default ProductsSection