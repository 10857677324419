import {createMarkup} from "../../../utils/markup-creator";
import React, {useEffect, useState} from "react";
import {SectionType} from "../../types";
import Icon from "../icons";

type SectionProps = {
    section: SectionType
    index: any
    [key: string]: any
}

type Plan = {
    plan?: string
    members?: string
    min_age?: number
    max_age?: number
    price?: number | undefined
    annual_share?: number | undefined
    annual_share_info?: string
    annual_max?: number | undefined
    annual_max_info?: string
    wellness_preventative?: number | undefined
    wellness_preventative_info?: string
    primary_care_visit?: number | undefined
    urgent_care_visit?: number | undefined
    rx_benefits?: string
    rx_benefits_info?: string
}

const ToolTip = ({status, text}: { status: boolean, text: string | undefined }) => {
    return <div className="relative">
        {status && (
            <div role="tooltip"
                 className="z-20 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-gray-500 p-4 rounded">
                <svg className="absolute left-0 -ml-2 bottom-0 top-0 h-full" width="9px" height="16px"
                     viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFFFFF">
                            <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                                <g id="Group-2" transform="translate(24.000000, 0.000000)">
                                    <polygon id="Triangle"
                                             transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                                             points="4.5 57.5 12.5 66.5 -3.5 66.5"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <p className="text-white">{text}</p>
            </div>
        )}{" "}
    </div>
}

const Currency = (number: number | undefined) => {
    return number?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
    })
}

const ThankYouSection = ({section, index, ...props}: SectionProps) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setemail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [namePlan, setNamePlan] = useState('')
    const [ratePlan, setRatePlan] = useState('')
    const [product, setProduct] = useState<Plan>({})

    const [tooltipStatus, setTooltipStatus] = useState("")

    //     window.sessionStorage.setItem('firstName',data.firstName)
    // window.sessionStorage.setItem('lastName',data.lastName)
    // window.sessionStorage.setItem('email',data.email)
    // window.sessionStorage.setItem('dateOfBirth',data.dateOfBirth)
    // window.sessionStorage.setItem('familySize',data.familySize)
    useEffect(() => {
        // @ts-ignore
        setFirstName(window.sessionStorage.getItem('firstName'))
        // @ts-ignore
        setLastName(window.sessionStorage.getItem('lastName'))
        // @ts-ignore
        setemail(window.sessionStorage.getItem('email'))
        // @ts-ignore
        setPhoneNumber(window.sessionStorage.getItem('phoneNumber'))
        // @ts-ignore
        setNamePlan(window.sessionStorage.getItem('namePlan'))
        // @ts-ignore
        setRatePlan(window.sessionStorage.getItem('ratePlan'))
        // @ts-ignore
        setProduct(JSON.parse(window.sessionStorage.getItem('product')))
    }, [])
    return (
        <div className={`relative ${section.advanced?.class || ``}`}>
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
                {section.blurb && <div className="pb-6" dangerouslySetInnerHTML={createMarkup(section.blurb)}/>}
                <h4 className={"pt-5"} style={{fontFamily: "Montserrat"}}>
                    Summary
                </h4>
                <div className={"grid grid-cols-3 gap-4"}>
                    <div className={"col-span-3 md:col-span-2 border border-gray-200 rounded-md p-5"}>
                        <div className="grid grid-cols-8 gap-4 pb-5">
                            <div className={"col-span-1"}>
                                <img
                                    style={{objectFit: 'contain', height: 50, width: 50}}
                                    src="/images/uploads/family.png"
                                    alt='uhsm'
                                />
                            </div>
                            <div className={"col-span-7 md:col-span-2 col-start-2"}>
                                <div className={"text-sm"} style={{fontFamily: 'Montserrat'}}>
                                    Applicant
                                </div>
                                <div className={"text-md truncate"} style={{fontFamily: 'Montserrat'}}>
                                    {firstName} {lastName}
                                </div>
                            </div>
                            <div className={"col-span-7 md:col-span-2  col-start-2"}>
                                <div className={"text-sm"} style={{fontFamily: 'Montserrat'}}>
                                    Phone
                                </div>
                                <div className={"text-md truncate"} style={{fontFamily: 'Montserrat'}}>
                                    {phoneNumber}
                                </div>
                            </div>
                            <div className={"col-span-7 md:col-span-3  col-start-2"}>
                                <div className={"text-sm"} style={{fontFamily: 'Montserrat'}}>
                                    Email
                                </div>
                                <div className={"text-md truncate"} style={{fontFamily: 'Montserrat'}}>
                                    {email}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-8 gap-4 border-t pt-5">
                            <div className={"col-span-1"}>
                                <img
                                    style={{objectFit: 'contain', height: 50, width: 50}}
                                    src="/images/uploads/uhsm.png"
                                    alt='uhsm'
                                />
                            </div>
                            <div className={"col-span-7"}>
                                <div className={"grid grid-cols-3 md:grid-cols-4 gap-2"}>
                                    <div className={"col-span-2"}>
                                        <h5 className={"font-bold  text-2xl"}
                                            style={{fontFamily: 'Nunito'}}>
                                            {product.plan}
                                        </h5>

                                        {/*    <div className={"flex flex-row"}>*/}
                                        {/*<span style={{fontFamily: 'Nunito'}}*/}
                                        {/*      className={"font-light text-sm pr-2 text-gray-400"}>*/}
                                        {/*    Rating:*/}
                                        {/*</span>*/}
                                        {/*        <img*/}
                                        {/*            className={'block md:hidden lg:block'}*/}
                                        {/*            style={{objectFit: 'contain', height: 14, width: 14}}*/}
                                        {/*            src="/images/uploads/star_fill.png"*/}
                                        {/*            alt='star'*/}
                                        {/*        />*/}
                                        {/*        <img*/}
                                        {/*            className={'block md:hidden lg:block'}*/}
                                        {/*            style={{objectFit: 'contain', height: 14, width: 14}}*/}
                                        {/*            src="/images/uploads/star_fill.png"*/}
                                        {/*            alt='star'*/}
                                        {/*        />*/}
                                        {/*        <img*/}
                                        {/*            className={'block md:hidden lg:block'}*/}
                                        {/*            style={{objectFit: 'contain', height: 14, width: 14}}*/}
                                        {/*            src="/images/uploads/star_fill.png"*/}
                                        {/*            alt='star'*/}
                                        {/*        />*/}
                                        {/*        <img*/}
                                        {/*            className={'block md:hidden lg:block'}*/}
                                        {/*            style={{objectFit: 'contain', height: 14, width: 14}}*/}
                                        {/*            src="/images/uploads/star_fill.png"*/}
                                        {/*            alt='star'*/}
                                        {/*        />*/}
                                        {/*        <img*/}
                                        {/*            className={'block md:hidden lg:block'}*/}
                                        {/*            style={{objectFit: 'contain', height: 14, width: 14}}*/}
                                        {/*            src="/images/uploads/star_fill.png"*/}
                                        {/*            alt='star'*/}
                                        {/*        />*/}

                                        {/*        /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                                        {/*        /!*      color="#F3B220"/>*!/*/}
                                        {/*        /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                                        {/*        /!*      color="#F3B220"/>*!/*/}
                                        {/*        /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                                        {/*        /!*      color="#F3B220"/>*!/*/}
                                        {/*        /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                                        {/*        /!*      color="#F3B220"/>*!/*/}
                                        {/*        /!*<Icon className={"mr-0.5 text-yellow-200"} icon="info" size={14}*!/*/}
                                        {/*        /!*      color="#F3B220"/>*!/*/}
                                        {/*    </div>*/}
                                    </div>
                                    <div className={"col-span-1"}>
                                        <h5 className={"text-right truncate text-2xl"} data-bs-placement="top"
                                            title="Tooltip on top"
                                            style={{fontFamily: 'Montserrat', color: '#F3B220'}}>
                                            {Currency(product?.price)}
                                        </h5>
                                    </div>
                                </div>
                                <div className={"pt-5"}>
                                    <div className={"grid grid-cols-3 md:grid-cols-4 gap-2"} style={{fontSize: 15}}>
                                        <div className={"col-span-2"}>
                                            <div className={"text-gray-400 align-middle"}>
                                                <Icon onMouseEnter={() => setTooltipStatus('Annual Share')}
                                                      onMouseLeave={() => setTooltipStatus('')} className={"mr-1"}
                                                      icon="info" size={15} color="gray-400"/>
                                                <span>Annual Share - AMCS</span>

                                                <ToolTip status={tooltipStatus === 'Annual Share'}
                                                         text={product?.annual_share_info}/>
                                            </div>
                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                            <span
                                                className={"text-black"}>{Currency(product?.annual_share)}</span>
                                        </div>

                                        <div className={"col-span-2"}>
                                            <div className={"text-gray-400"}>
                                                <Icon onMouseEnter={() => setTooltipStatus('Annual Max')}
                                                      onMouseLeave={() => setTooltipStatus('')}
                                                      className={"mr-1"} icon="info" size={15} color="gray-400"/>
                                                <span className={"text-gray-400"}>
                                        Annual Max
                                    </span>

                                                <ToolTip status={tooltipStatus === 'Annual Max'}
                                                         text={product?.annual_max_info}/>
                                            </div>
                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                            <span
                                                className={"text-black"}>{Currency(product?.annual_max)}</span>
                                        </div>

                                        <div className={"col-span-2"}>
                                            <div className={"text-gray-400 text-light"}>
                                                <Icon onMouseEnter={() => setTooltipStatus('Wellness')}
                                                      onMouseLeave={() => setTooltipStatus('')} className={"mr-1"}
                                                      icon="info" size={15} color="gray-400"/>
                                                <span className={"text-gray-400 text-light"}>
                                        Wellness & Preventive
                                    </span>
                                                <ToolTip status={tooltipStatus === 'Wellness'}
                                                         text={product?.wellness_preventative_info}/>

                                            </div>

                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                            <span
                                                className={"text-black"}>{Currency(product?.wellness_preventative)}</span>
                                        </div>

                                        <div className={"col-span-2"}>
                                            <span className={"text-gray-400 text-light"}>Primary Care Visit</span>
                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                            <span
                                                className={"text-black"}>{Currency(product?.primary_care_visit)}</span>
                                        </div>

                                        <div className={"col-span-2"}>
                                            <span className={"text-gray-400 text-light"}>Urgent Care Visit </span>
                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                             <span
                                                 className={"text-black"}>{Currency(product?.urgent_care_visit)}</span>
                                        </div>

                                        <div className={"col-span-2"}>
                                            <div className={"text-gray-400 text-light"}>
                                                <Icon onMouseEnter={() => setTooltipStatus('Rx')}
                                                      onMouseLeave={() => setTooltipStatus('')}
                                                      className={"mr-1"} icon="info" size={15} color="gray-400"/>
                                                <span className={"text-gray-400 text-light"}>
                                        Rx Benefits
                                    </span>
                                                <ToolTip status={tooltipStatus === 'Rx'}
                                                         text={product.rx_benefits_info}/>

                                            </div>
                                        </div>
                                        <div className={"col-span-1 text-right"}>
                                            <span className={"text-black"}>{product.rx_benefits}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-span-3 md:col-span-1  pb-5"}>
                        <div className={"border rounded"} style={{backgroundColor: '#28A138', borderColor: '#28A138'}}>

                            <div className={"p-5 pb-0 text-center "}
                                 style={{fontFamily: 'Montserrat', fontSize: 24}}>
                                <label style={{width: '100%'}}
                                       className={"text-center w-full text-white font-bold text-center"}>
                                    <Icon className={"mr-3 text-white "} icon="checkmark" size={40}
                                          color="yellow-700"/>
                                    Thank you, {firstName}!
                                </label>
                            </div>

                            <div className={"p-5 text-justify text-white"} style={{fontFamily: 'Nunito'}}>
                                Your application was successfully sent, and an agent will contact you soon.
                            </div>
                            <div className={"rounded bg-white p-5"}>
                                <p className={"p-5 text-center"} style={{fontFamily: 'Nunito'}}>
                                    Call us now
                                </p>


                                <div className={'flex flex-row align-middle justify-center'}>
                                    <img
                                        className={'block md:hidden lg:block'}
                                        style={{objectFit: 'contain', height: 34, width: 34}}
                                        src="/images/uploads/phone.png"
                                        alt='uhsm'
                                    />
                                    <label
                                        className={"text-center w-full text-white font-bold text-center"}
                                        style={{
                                            width: '100%',
                                            color: '#28A138',
                                            fontFamily: 'Montserrat',
                                            fontSize: 24,
                                            flexDirection: 'row',
                                            maxWidth: 240
                                        }}>

                                        (800) 318-6804
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ThankYouSection