import axios from "axios"

const buildLeadUpdate = function (ProductOfInterest: string): any {
    return {
        LeadID: window.sessionStorage.getItem('LeadID'),
        ProductOfInterest: ProductOfInterest,
    }
}

export const updateLead = async function (ProductOfInterest: string): Promise<boolean> {
    const data = buildLeadUpdate(ProductOfInterest)

    if (typeof window !== `undefined` && typeof window.datadogLogs !== `undefined`) {
        window.datadogLogs.logger.info(`track lead:`, data)
    }

    const response = await axios.post(`https://api.sureco.com/AQP/v2/api/Lead/UpdateLeadAndLeadSource`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const body = await response.data

    if (response.status < 200 || response.status > 299) {
        window.datadogLogs.logger.error(`submit lead error:`, body)
        throw new Error(body)
    }

    return true
}
