/* eslint-disable react/no-array-index-key */
import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import useSiteMetadata from "../../hooks/use-site-metadata"
import SkipNavLink from "../../components/skip-nav"
import SEO from "../../components/seo"
import Analytics from "../../components/analytics"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { PageType } from "../../types"

type PageProps = {
  page: PageType
  [key: string]: any
}

const Basic = ({ page, ...props }: PageProps) => {
  const { tealiumUrlProd } = useSiteMetadata()
  // If tealium is configured, the GDPR content will be served through there

  return (
    <React.Fragment>
      <SEO title={page.seo?.title} description={page.seo?.description} />
      <SkipNavLink>Skip to content</SkipNavLink>
      <Header header={page?.header} {...props} />
      <div id="skip-nav" className="" style={{ minHeight: `90vh` }}>
        {page?.body && (
          <section>
            <MDXRenderer>{page?.body}</MDXRenderer>
          </section>
        )}
      </div>
      <Footer footer={page?.footer} />

      {!tealiumUrlProd && (
        <CookieConsent buttonText="Accept" style={{}} buttonStyle={{ backgroundColor: `white`, fontSize: `0.8em`, height: `100%`, padding: `5px 30px`, marginTop: `0px` }}>
          <div className="text-xs md:text-sm">
            We use cookies to optimize our site and service; and may share this data with our partners. By continuing to use our site, you agree to our terms:&nbsp;
            <Link className="text-white underline" to="/do-not-sell-my-personal-information">
              Cookie Policy
            </Link>
            &nbsp;-&nbsp;
            <Link className="text-white underline" to="/privacy-terms">
              Privacy &amp; Terms
            </Link>
          </div>
        </CookieConsent>
      )}

      <Analytics />
    </React.Fragment>
  )
}

export default Basic
