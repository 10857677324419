import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { PageType } from "../types"
import Landing1 from "./pages/landing1"
import Basic from "./pages/basic"

export const query = graphql`
  query ($slug: String!) {
    page(slug: { eq: $slug }) {
      slug
      slug2
      templateId
      seo {
        title
        description
      }
      header {
        templateId
        logo
        logoLink
        logoHeightLg
        logoHeightSm
        links {
          url
          name
          icon
          class
          description
        }
        notice
        advanced {
          backgroundImageLg
          backgroundImageSm
          backgroundHeight
          backgroundPosition
          backgroundFit
          backgroundColor
          background2ImageLg
          background2ImageSm
          background2Height
          background2Position
          background2Fit
          background2Color
          class
          class2
        }
      }
      footer {
        templateId
        logo
        advanced {
          class
          hideFooterTopText
        }
      }
      sections {
        templateId
        blurb
        blurb2
        advanced {
          backgroundImageLg
          backgroundImageSm
          backgroundHeight
          backgroundPosition
          backgroundFit
          backgroundColor
          background2ImageLg
          background2ImageSm
          background2Height
          background2Position
          background2Fit
          background2Color
          class
          class2
        }
        cards {
          title
          blurb
          blurb2
          footer
          link
          class
          icon
          minHeight
        }
        qa {
          q
          a
          class
        }
        links {
          url
          name
          icon
          class
          description
        }
        form {
          templateId
          destination
          submitText
        }
        iframe {
          url
          title
        }
        testimonials {
          name
          location
          testimonial
          image
        }
        title
        subtitle
        description
        blocks {
          title
          subtitle
          description
          image
          blocks {
            title
            subtitle
            description
            image
          }
        }
        items
      }
      body
    }
  }
`

type PageProps = {
  data: {
    page: PageType
  }
  [key: string]: any
}

export default function Page({ data: { page }, ...props }: PageProps) {
  let urlChange
  if (typeof window !== "undefined") {
    urlChange = new URL(window.location)
  }
  useEffect(() => {
    if (typeof sbjs !== `undefined`) {
      sbjs.init({
        domain: props.location.hostname,
        lifetime: 0,
        session_length: 0,
        timezone_offset: 8,
      });
    }
    if (typeof utag !== `undefined`) {

      utag.link({
        "tealium_event": "ga_page_view"      //Required
      });
    }
  }
    , [urlChange])

  return page.templateId === `landing1` ?
    <>  
      <Landing1 page={page} {...props} />
    </> :
    <>
      <Basic page={page} {...props} />
    </>
}
