import React from "react"

type SkipNavLinkProps = {
  children: React.ReactNode
}

const SkipNavLink = ({ children, ...props }: SkipNavLinkProps) => (
  <a {...props} className="skip-to-content-link" href="#skip-nav" data-skip-link="true">
    {children}
  </a>
)

export default SkipNavLink
